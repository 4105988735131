const navLink = document.querySelector('.nav-bar__link');
const panelNav = document.querySelector('.panel-nav');
const navItems = panelNav.querySelectorAll('.panel-nav__item');

navLink.addEventListener("click", () => {
  panelNav.classList.toggle("active");
  panelNav.addEventListener("transitionend", handlePanelNavIntroTransitionEnd);
});

function handlePanelNavIntroTransitionEnd(e) {
  if(e.propertyName === 'width') {
    panelNav.removeEventListener(
      "transitionend",
      handlePanelNavIntroTransitionEnd
    );
    panelNav.classList.contains("active")
      ? doNavWaterfall()
      : resetWaterfall();
  }
};

function doNavWaterfall(e) {
  const waterfallStagger = 100;
  navItems.forEach((item, index) => {
    item.classList.add('waterfall-offset');
    setTimeout(() => {
      item.classList.add("waterfall-show");
    }, index * waterfallStagger);
  });
};

function resetWaterfall() {
  navItems.forEach(item => {
    item.classList.remove('waterfall-offset', 'waterfall-show');
  });
}
